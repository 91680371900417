import {
  split,
  ApolloClient,
  createHttpLink,
  InMemoryCache
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import jwtDefaultConfig from '../@core/auth/jwt/jwtDefaultConfig';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';

const env = process.env.NODE_ENV;

const URI =
  env === 'development'
    ? process.env.REACT_APP_DEVELOPMENT_GRAPHQL_URL
    : process.env.REACT_APP_PRODUCTION_GRAPHQL_URL;

const WS_URI =
  env === 'development'
    ? process.env.REACT_APP_DEVELOPMENT_GRAPHQL_WS_URL
    : process.env.REACT_APP_PRODUCTION_GRAPHQL_WS_URL;

const httpLink = createHttpLink({
  uri: URI
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: WS_URI,
    lazy: true,
    shouldRetry: true,
    connectionParams: async () => {
      const token = localStorage.getItem(jwtDefaultConfig.storageTokenKeyName);
      if (!token) {
        return {};
      }
      return {
        Authorization: token ? `Bearer ${token}` : ''
      };
    }
  })
);

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(jwtDefaultConfig.storageTokenKeyName);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : undefined
    }
  };
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  link: authLink.concat(splitLink),
  cache: new InMemoryCache()
});

export default client;
